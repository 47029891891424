import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  FormControl,
  Typography,
  Menu,
  InputBase,
  Paper,
  Dialog,
  DialogContent,
  Avatar,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FiTwitter, FiYoutube } from "react-icons/fi";
import { AiOutlineReddit } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { BsCircleHalf, BsSearch } from "react-icons/bs";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { CgSearch } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { ACTIVE_NETWORK, getNetworkDetails } from "src/constants";
import { UserContext } from "src/context/User";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import { useWeb3React } from "@web3-react/core";
import { processName, sortAddress } from "src/utils";
import SearchBox from "src/layouts/HomeLayout/DashboardLayout/SearchBox";
import SettingsContext from "src/context/SettingsContext";
import { BsFillCaretDownFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { getWeb3Obj, getContract, swichNetworkHandler } from "src/utils";
import { FaRegCopy } from "react-icons/fa";
// import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import ConnectWallet from "src/views/pages/Connect/ConnectWallet";

import { IoClose } from "react-icons/io5";

const headersData = [
  // {
  //   label: "Add Branding",
  //   href: "/add-brand",
  // },
  {
    label: "Marketplace",
    href: "/explore",
  },
  {
    label: "Collections",
    href: "/collections",
  },
  // {
  //   label: "Feature",
  //   href: "/roadmap",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    color: theme.palette.primary.main,
    // height: "31px",
    padding: "0px 12px",
    letterSpacing: "1px",
    // marginLeft: "15px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#51ACED",
    },
    "&:hover": {
      color: "#51ACED",
      backgroundColor: "transparent !important",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    background: theme.palette.background.blur,
    border: "2px solid rgba(0, 0, 0, 0.025)",
    backdropFilter: "blur(4px)",
    borderRadius: 15,
    padding: "6px 33px",
    marginTop: 25,
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#fff",
    background: theme.palette.background.default,
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    // position: "absolute",
    // top: "0px",
    // right: "-18px",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    // fontSize: "16px",
    // fontWeight: "400",
    // fontStyle: "normal",
    // letterSpacing: "-0.6px",
    // lineHeight: "1.75",
    // color: "#000",
    // paddingLeft: 0,
    // borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  searchiconBox: {
    background: theme.palette.background.blur,
  },
  search: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#DAF4FF",
    border: "1px solid #fff",

    marginLeft: 20,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: "0px 9px",
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: "8px 6px 8px 0px",
    fontSize: "12px",
    marginTop: "-2px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
  },
  searcBox: {
    backgroundColor: "#DAF4FF",
    // border: "1px solid #daf4ff",
    borderRadius: " 50px",
  },
  menuMobile1: {
    // marginLeft: "10px",
    padding: "15px 0",
    "& h4": {
      fontSize: "12px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      // backgroundColor: theme.palette.background.card,
      backgroundColor: "transparent",
      // borderRadius: "40px",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        // maxHeight: "100%",
      },
    },
  },
  menuMobile2: {
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: theme.palette.background.dark,
      margin: "0 5px",
      whiteSpace: "pre",
      fontWeight: "300",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#51ACED",
      // borderRadius: "40px",
    },
  },
  menuMobiledrawer: {
    "& h4": {
      fontSize: "16px",
      lineHeight: " 17px",
      color: "#000",

      whiteSpace: "pre",
      fontWeight: "400",
    },
  },
  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 100px)",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      overflowY: "auto",
    },
  },
}));

export default function Header() {
  const { account, chainId } = useWeb3React();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (Boolean(anchorEl2)) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [anchorEl2]);
  useEffect(() => {
    if (Boolean(anchorEl1)) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [anchorEl1]);

  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updateName, setUpdateName] = useState(false);
  // const searchTextRef = React.useRef(null);
  const location = useLocation();
  const [networkDetails, setNetworkDetails] = React.useState([]);

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    searchiconBox,
    toolbar,
    drawerContainer,
    menuMobile1,
    menuMobile2,
    drawericon,
    logoDrawer,
    mainHeader,
    search,
    inputInput,
    searchIcon,

    inputRoot,
    searcBox,
    menuMobiledrawer,
    marginbtn,
  } = useStyles();
  const history = useHistory();

  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [isValidProfile, setisValidProfile] = useState(false);
  function isImageUrlValid(url) {
    // setCoverImage64(url);
    const img = new Image();
    img.onload = function () {
      setisValidProfile(true);
      // setCoverImage64(url);
    };
    img.onerror = function () {
      setisValidProfile(false);
      // setCoverImage64("");
    };
    img.src = url;
  }
  useEffect(() => {
    isImageUrlValid(user.userData?.profilePic);
  }, [user.userData?.profilePic]);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  const handleButtonClick = (e, href) => {
    // Prevent default action
    e.preventDefault();

    // Check if any form fields have unsaved data
    const isFormFilled = Object.values(user.createNftData).some(
      (value) => value !== ""
    );

    if (isFormFilled) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Do you really want to leave?"
      );
      if (confirmLeave) {
        // Navigate to the intended route
        history.push(href);
      }
    } else {
      // Navigate directly if no unsaved changes
      history.push(href);
    }
  };
  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}

          {/* {SearchBoxx} */}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            // color="inherit"
            className={menuButton}
            // className={menuButton1}
            onClick={() => {
              window.open("https://websitebuilder.hovr.site/");
            }}
          >
            Website Builder
          </Button>
          <Button
            // style={{ fontSize: "14px", fontWeight: "300" }}
            // color="inherit"
            className={menuButton}
            // className={menuButton1}
            onClick={() => {
              window.open("https://domains.hovr.site/");
            }}
          >
            Buy Domain
          </Button>
          {getMenuButtons()}
          {user?.isLogin && (
            <Button
              variant="text"
              color="primary"
              aria-label="delete"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick2}
              className={menuMobile2}
              style={{ fontWeight: "300", fontSize: "12px" }}
            >
              Add Your Brand
              <BsFillCaretDownFill
                style={{ fontSize: "16px", marginLeft: "8px" }}
              />
            </Button>
          )}
          <StyledMenu
            id="simple-menu"
            disableScrollLock={true}
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose5}
          >
            <MenuItem
              onClick={() => {
                if (user?.isLogin) {
                  history.push("/add-brand");
                } else {
                  handleClickOpen();
                }
              }}
            >
              Add Your Brand
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/brandlist");
              }}
            >
              Explore
            </MenuItem>
          </StyledMenu>
          {user?.isLogin ? (
            <Box className="" display="flex">
              <Button
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick1}
                className={menuMobile1}
                style={{ marginLeft: "10px" }}
                size="small"
                color="primary"
              >
                <Avatar
                  type="profile"
                  src={isValidProfile && user.userData?.profilePic}
                  alt=""
                  style={
                    user?.isLogin
                      ? { height: "40px", width: "40px" }
                      : { width: "0px" }
                  }
                />
                <Typography
                  variant="h4"
                  color="primary"
                  title={
                    user?.userData?.name
                      ? user?.userData?.name
                      : user?.userData?.walletAddress
                  }
                >
                  {" "}
                  {user?.userData?.name
                    ? processName(user?.userData?.name, 10)
                    : sortAddress(user?.userData?.walletAddress)}
                </Typography>
                <BsFillCaretDownFill style={{ fontSize: "16px" }} />
              </Button>
              <Box style={{ display: "flex" }}>
                <IconButton
                  className="searchiconBox"
                  onClick={() => {
                    history.push("/notification");
                  }}
                >
                  <NotificationsNoneIcon />
                </IconButton>
              </Box>{" "}
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              // to="/conect-wallet"
              // component={Link}
              style={{ marginLeft: "15px", whiteSpace: "pre" }}
            >
              Connect Wallet
            </Button>
          )}
          <Box className={divstake}>
            <StyledMenu
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose4}
            >
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/create");
                }}
              >
                Create NFT
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/create-nfttype");
                }}
              >
                Create Physical NFT
              </MenuItem>

              <MenuItem
                onClick={() => {
                  user.logoutHandler();
                  setAnchorEl1();
                }}
              >
                Disconnect
              </MenuItem>
            </StyledMenu>
          </Box>
          <Box className="themeButton" style={{ margin: "0 20px" }}>
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                color="primary"
                style={{ background: "#FFFFFF0D" }}
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                color="primary"
                style={{ background: "#FFFFFF0D" }}
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            )}
          </Box>{" "}
          <Box style={{ display: "flex" }}>
            <IconButton
              className="searchiconBox"
              style={{ background: "#FFFFFF0D" }}
              // style={{ paddingRight: "0px" }}
              onClick={() => setDialogOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          </Box>
          {/* <Alert variant="filled" severity="warning">
            Please do not refresh the page!
          </Alert>
        // </Box> */}
        </Box>

        {/* <Dialog onClose={handleClose} open={open} maxWidth="xs">
          <IconButton
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "0",

            }}>
            <IoClose />
          </IconButton>

          <DialogContent dividers>
            <ConnectWallet mainModalClose={handleClose} />
          </DialogContent>

        </Dialog> */}
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const [searchInput, setSearchInput] = useState("");
  const searchTextRef = React.useRef(null);

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            {/* <Button
              style={{
                fontSize: "12px",
                fontWeight: "400",
                marginLeft: "-8px",
                marginBottom: "10px",
              }}
              // color="inherit"
              className={menuButton}
              // className={menuButton1}
              onClick={() => {
                window.open("https://websitebuilder.hovr.site/");
              }}
            >
              Website Builder 
            </Button> */}

            <Button
              {...{
                key: "Website Builder",
                color: "inherit",
              }}
              onClick={() => {
                window.open("https://websitebuilder.hovr.site/");
              }}
              style={{ padding: "6px 8px 6px 0" }}
            >
              <MenuItem className={menuMobile}> Website Builder </MenuItem>
            </Button>
            <Button
              {...{
                key: "Buy Domain",
                color: "inherit",
              }}
              onClick={() => {
                window.open("https://domains.hovr.site/");
              }}
              style={{ padding: "6px 8px 6px 0" }}
            >
              <MenuItem className={menuMobile}>Buy Domain </MenuItem>
            </Button>

            {getDrawerChoices()}
            <div>
              {user?.isLogin && (
                <Button
                  {...{
                    key: "Add Your Brand",
                    color: "inherit",
                  }}
                  style={{ padding: "6px 8px 6px 0" }}
                  onClick={handleClick2}
                >
                  <MenuItem className={menuMobile}>
                    {" "}
                    Add Your Brand
                    <BsFillCaretDownFill
                      style={{ fontSize: "16px", marginLeft: "8px" }}
                    />
                  </MenuItem>
                </Button>
              )}
              <div>
                <Box className={divstake}>
                  <StyledMenu
                    id="simple-menu"
                    disableScrollLock={true}
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={handleClose5}
                  >
                    <MenuItem
                      onClick={() => {
                        if (user?.isLogin) {
                          history.push("/add-brand");
                        } else {
                          history.push({
                            pathname: "/conect-wallet",
                            search: "add-brand",
                          });
                        }
                      }}
                    >
                      Add Your Brand
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push("/brandlist");
                      }}
                    >
                      Explore
                    </MenuItem>
                  </StyledMenu>
                </Box>
              </div>
              {user?.isLogin ? (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick1}
                  className={menuMobile1}
                  size="small"
                  // style={{ padding: "16px" }}
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    <Avatar
                      type="profile"
                      src={isValidProfile && user.userData?.profilePic}
                      alt=""
                      style={
                        user?.isLogin
                          ? { height: "40px", width: "40px" }
                          : { width: "0px" }
                      }
                    />
                  </figure>
                  <Typography
                    variant="h4"
                    color="primary"
                    title={
                      user?.userData?.name
                        ? user?.userData?.name
                        : user?.userData?.walletAddress
                    }
                  >
                    {" "}
                    {user?.userData?.name
                      ? processName(user?.userData?.name, 10)
                      : sortAddress(user?.userData?.walletAddress)}
                  </Typography>
                  <BsFillCaretDownFill
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      marginLeft: "8px",
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setUpdateMinSatkeOpen(true)}
                  className={menuMobile1}
                  size="small"
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    {/* <img
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : ""
                      }
                      alt=""
                    /> */}
                    <Avatar
                      type="profile"
                      src={isValidProfile && user.userData?.profilePic}
                      alt=""
                      style={
                        user?.isLogin
                          ? { height: "40px", width: "40px" }
                          : { width: "0px" }
                      }
                    />
                  </figure>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    // to="/conect-wallet"
                    // component={Link}
                    onClick={() => {
                      handleDrawerClose();
                      handleClickOpen();
                    }}
                    style={{ whiteSpace: "pre", marginTop: "10px" }}
                  >
                    Connect Wallet
                  </Button>
                </IconButton>
              )}

              <Box className={divstake}>
                <Menu
                  id="simple-menu"
                  disableScrollLock={true}
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose4}
                >
                  <MenuItem
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/create");
                    }}
                  >
                    Create NFT
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/create-nfttype");
                    }}
                  >
                    Create Physical NFT
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      user.logoutHandler();
                      setAnchorEl1();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>

              <Box className="bottomsocialButton">
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.facebook.com/hovr.site/"
                  >
                    <FaFacebookF />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.youtube.com/watch?v=CMqRqd97rQg"
                  >
                    <FiYoutube />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.instagram.com/hovr.site/?igshid=YmMyMTA2M2Y%3D"
                  >
                    <FaInstagram />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank" href="https://twitter.com/HovrSite">
                    <FaXTwitter />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.reddit.com/r/hovrNFT/"
                  >
                    <AiOutlineReddit style={{ fontSize: "20px" }} />
                  </Link>
                </IconButton>
              </Box>
            </div>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            className="searchiconBox"
            onClick={() => {
              history.push("/notification");
            }}
          >
            <NotificationsNoneIcon />
          </IconButton>

          <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            )}
          </Box>

          <IconButton
            onClick={() => setDialogOpen(true)}
            className="searchiconBox"
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#197ab3", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  //mobile end
  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            key={label}
            color="inherit"
            className={menuButton1}
            onClick={(e) => handleButtonClick(e, href)} // Handle click event
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const SearchBoxx = (
    <SearchBox
      search={search}
      inputRoot={inputRoot}
      inputInput={inputInput}
      searchIcon={searchIcon}
    />
  );

  const getMenuButtons = (activeClassName) => {
    return headersData.map(({ label, href }) => {
      const isActive = window.location.pathname === href;
      return (
        <Button
          key={label}
          color="inherit"
          className={`${menuButton} ${isActive ? "active" : ""}`}
          onClick={(e) => handleButtonClick(e, href)} // Handle click event
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Container maxWidth="lg">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      {dialogOpen && (
        <Paper>
          <Dialog
            fullWidth
            maxWidth="lg"
            className={classes.searchdiaogBox}
            style={{
              position: "absolute",
              top: "10%",
              // minHeight: "695px",
            }}
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
            }}
          >
            <IconButton
              className="closeButton"
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </IconButton>

            <Box className="dialogBoxHeight">
              <SearchBox />
            </Box>
          </Dialog>
        </Paper>
      )}
      <Dialog onClose={handleClose} open={open} maxWidth="xs">
        <IconButton
          onClick={handleClose}
          style={{
            position: "absolute",
            right: "0",
          }}
        >
          <IoClose />
        </IconButton>

        <DialogContent dividers>
          <ConnectWallet mainModalClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
