import React, { useContext, useEffect } from "react";
import {
  Typography,
  Box,
  makeStyles,
  IconButton,
  Button,
  Link as RouterLink,
  Paper,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import { UserContext } from "src/context/User";
import ErrorIcon from "@material-ui/icons/Error";
import { SUPPORTED_WALLETS } from "src/connectors";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  walletBox: {
    border: "1px solid #E9E9E9",
    borderRadius: "25px",
    textAlign: "center",
    cursor: "pointer",
    border: "1px solid transparent",
    // minHeight: "185px",
    "& img": {
      width: "auto",
      maxWidth: "35px",
    },
    "& h5": {
      fontWeight: "500",
      fontSize: "20px",
    },
    "& lavel": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3D3D3D",
    },
  },
  paper: {
    overflowY: "unset",
  },
  customizedButton: {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: "rgb(120, 120, 120)",
  },
  dialogBox: {
    padding: "30px",
    width: "325px !important",
    "@media(max-width:500px)": {
      width: "300px !important",
    },
    "@media(max-width:400px)": {
      width: "270px !important",
    },
  },
  metaButtonBox: {
    display: "flex",
    padding: "15px",
    borderRadius: "5px",
    // "background": "#323232",
    background: theme.palette.background.gray,
  },
}));
const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: "#4DDAFF !important",
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);
export default function UsersCard(props) {
  const {
    type,
    data,
    onWalletConnectHandler,
    isLoading,
    selectedWallet,
    errorMsg,
    handleClickOpen2,
    open2,
    setOpen2,
    open3,
    setOpen3,
    handleClickOpen3,
  } = props;
  const [checked, setChecked] = React.useState(false);
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const history = useHistory();
  const user = useContext(UserContext);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const classes = useStyles();

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangecheckedTerms = (event) => {
    setCheckedTerms(event.target.checked);
  };
  const walletBlocktoast = () => {
    toast.warn("You have been blocked");
    handleClose2();
    history.push("/");
  };

  return (
    <Box mt={3}>
      <Box className={classes.walletBox} onClick={handleClickOpen2}>
        {SUPPORTED_WALLETS.map((item, i) => {
          return (
            <Box
              key={i}
              className={classes.metamaskhead}
              setOpen2={setOpen2}
              open2={open2}
              handleClickOpen2={handleClickOpen2}
              onWalletConnectHandler={(data) => {
                onWalletConnectHandler(data);
              }}
              index={i}
              data={SUPPORTED_WALLETS[0]}
            >
              <Box className={classes.metaButtonBox}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <img src={item.data?.iconName} alt="" />
                  </Box>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography variant="h5" color="primary">
                    {" "}
                    {item.data?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {open2 && (
        <ShowConfirmationWallet
          open2={open2}
          handleClose2={handleClose2}
          handleChangecheckedTerms={handleChangecheckedTerms}
          checked={checked}
          checkedTerms={checkedTerms}
          classes={classes}
          handleChange={handleChange}
          user={user}
          data={data}
          walletBlocktoast={walletBlocktoast}
          onWalletConnectHandler={onWalletConnectHandler}
          history={history}
        />
      )}

      <Dialog
        open={user.errorPop}
        onClose={() => user.setErrorPop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogActions>
          <IconButton
            onClick={handleClose2}
            className={classes.customizedButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent className={classes.dialogBox}>
          <Box className="modal_text">
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ErrorIcon style={{ color: "red" }} />
            </Box>
            <Typography variant="h6" align="center">
              Error
            </Typography>
            <Box style={{ textAlign: "center" }}>
              <Typography variant="body2">{user.errorMsg}</Typography>
              <Typography variant="body2">
                If the problem persist please{" "}
                <span style={{ color: "#039be3" }}>Contact support</span>
              </Typography>
              <Button
                style={{
                  backgroundColor: "#039be3",

                  borderRadius: "50px",
                  height: "40px",
                  marginTop: "12px",
                  width: "160px",
                }}
                color="primary"
                onClick={() => {
                  const selectectWalletDetails = SUPPORTED_WALLETS.filter(
                    (data) =>
                      data.name === window.sessionStorage.getItem("walletName")
                  );
                  onWalletConnectHandler(selectectWalletDetails[0]);
                }}
              >
                TRY AGAIN
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const ShowConfirmationWallet = ({
  open2,
  handleClose2,
  handleChangecheckedTerms,
  checked,
  checkedTerms,
  classes,
  handleChange,
  user,
  data,
  walletBlocktoast,
  onWalletConnectHandler,
  history,
}) => {
  useEffect(() => {
    if (user.errorMsg) {
      user.setErrorMsg("");
      history.push("/learn-more");
    }
  }, [user.errorMsg]);
  return (
    <Dialog
      open={open2}
      onClose={handleClose2}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
    >
      <DialogActions>
        <IconButton onClick={handleClose2} className={classes.customizedButton}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogBox}>
        <Box>
          <Box mb={2}>
            <Typography variant="h3" color="primary">
              HOVR Terms Of Service
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="primary"
            style={{ marginBottom: "10px" }}
          >
            Please take a few minutes to read and understand NftTokenABI terms
            of services to continue all need to accept the terms of services by
            checking the box
          </Typography>
          <Typography variant="body2" color="primary">
            <GreenCheckbox
              onChange={handleChange}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            I am atleast 13 years old
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            style={{ marginBottom: "10px" }}
          >
            <GreenCheckbox
              onChange={handleChangecheckedTerms}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            I accept the HOVR{" "}
            <Link
              target="_blank"
              to="/terms-conditions"
              style={{ color: "#3397FE", textDecoration: "none" }}
            >
              Terms of Services
            </Link>
          </Typography>
          <Button
            onClick={handleClose2}
            variant="contained"
            color="primary"
            autoFocus
          >
            Cancel
          </Button>{" "}
          &nbsp;
          {checked && checkedTerms && user?.walletdata === "BLOCK" ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={walletBlocktoast}
              autoFocus
            >
              Proceed
            </Button>
          ) : (
            <>
              {checked && checkedTerms && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      window.localStorage.setItem("isAccepted", true);
                      onWalletConnectHandler(data);
                    }}
                    autoFocus
                  >
                    Proceed
                  </Button>
                </>

                // <Button
                //   variant="contained"
                //   size="small"
                //   color="primary"
                //   onClick={() => onWalletConnectHandler(data)}
                //   autoFocus
                // >
                //   Proceed
                // </Button>
              )}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
