import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import Page from "src/component/Page";
import axios from "axios";
import ApiConfig from "src/ApiConfig/ApiConfig";
import Items from "./Items";
import Users from "./Users";
import Collection from "./Collection";
import useDebounce from "src/CutomHooks/Debounce";
import { UserContext } from "src/context/User";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  Padding_Top: {
    // paddingTop: "25px",

    "& .searchScrollBox": {
      // height: "65vh",
      // overflowY: "scroll",
      // overflowX: "hidden",
      // marginBottom: "30px",
      marginBottom: "30px",
      // overflow: "auto",
      // maxHeight: "446px",
      maxHeight: "460px",
    },
  },

  PageHeading: {
    fontWeight: "400",
    marginBottom: "10px",
    lineHeight: "39px",
    // color: "#898989",
    marginTop: "20px",
    "& span": {
      color: theme.palette.text.primary,
    },
  },
}));
function Search({ search }) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [tabview, setTabView] = useState("item");
  const [searchResult, setSearchResult] = useState();
  const deb = useDebounce(user.glbSearch, 2000);
  const [isLoading, setIsLoading] = useState(false);

  const searchHandler = async (cancelTokenSource, search) => {
    try {
      setIsLoading(true);
      setSearchResult();
      const res = await axios.get(ApiConfig.dashboardSearch, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
        params: {
          search: search,
        },
      });
      if (res.data.statusCode == 200) {
        setSearchResult(res.data.result);
      } else {
        setSearchResult();
      }
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      setSearchResult();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (user.glbSearch) {
      searchHandler(cancelTokenSource, user.glbSearch);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [deb]);

  return (
    <Page title="Marketplace for NFTs">
      <Box className={classes.Padding_Top}>
        <Typography
          variant="h4"
          color="primary"
          className={classes.PageHeading}
        >
          Search results for{" "}
          <span style={{ color: "#039be3", wordBreak: "break-all" }}>
            {user.glbSearch}
          </span>
        </Typography>
        <Box className="searchScrollBox">
          <Box
            className="TabButtonsBox"
            style={{ borderBottom: "1.5px solid #8080803b" }}
          >
            <Button
              className={tabview === "item" ? "active" : " "}
              onClick={() => setTabView("item")}
            >
              Items<span>{searchResult?.orderResult?.length}</span>
            </Button>
            <Button
              className={tabview === "users" ? "active" : " "}
              onClick={() => setTabView("users")}
            >
              Users<span>{searchResult?.userResult?.length}</span>
            </Button>
            <Button
              className={tabview === "collection" ? "active" : " "}
              onClick={() => setTabView("collection")}
            >
              Collection<span>{searchResult?.collectionResult?.length}</span>
            </Button>
          </Box>
          <Box className="TabButtonsContant">
            {isLoading && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <ButtonCircularProgress />
              </Box>
            )}
            {tabview === "item" ? (
              <Items orderList={searchResult?.orderResult} />
            ) : (
              ""
            )}
            {tabview === "users" ? (
              <Users searchUserList={searchResult?.userResult} />
            ) : (
              ""
            )}
            {tabview === "collection" ? (
              <Collection collectionList={searchResult?.collectionResult} />
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default Search;
