import React, { useContext } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import { UserContext } from "src/context/User";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FiTwitter, FiYoutube } from "react-icons/fi";
import { AiOutlineReddit } from "react-icons/ai";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { FaXTwitter } from "react-icons/fa6";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: theme.palette.background.card,
    position: "relative",
    padding: "75px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    "& .copy": {
      borderTop: "1px solid #d0d0d017",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
    },
    "& ul": {
      paddingLeft: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        "& svg": {
          marginRight: "10px",
          color: "#408FAC",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#408FAC",
      fontSize: "15px",
    },
    "& p": {
      color: theme.palette.text.gray,
      fontSize: "14px",
      fontWeight: "300",
    },
    "& h6": {
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },
    "& .MuiIconButton-root": {
      padding: "0px 13px 0px 0px",
    },
    "& a": {
      color: theme.palette.text.gray,
      fontWeight: 300,
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px",
      },
      "& :hover": {
        color: "#35a5f5",
        textDecoration: "none",
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const { account, library, chainId } = useWeb3React();
  const toastmsg = () => {
    toast.warn("Please connect your wallet");
  };
  const toastmsgcreate = () => {
    toast.warn("Please connect your wallet");
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <Box mr={8}>
                <Box mb={2}>
                  {" "}
                  <RouterLink to="/">
                    <img src="images/logo.png" alt="" /> <br />
                  </RouterLink>
                </Box>
                <Typography variant="body1">
                  NFT marketplace with the lowest transaction fees in the world.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6" color="primary">
                    Web Link
                  </Typography>
                  <List>
                    <ListItem to="/collections" component={RouterLink}>
                      Collections
                    </ListItem>

                    {/* <ListItem to="/ranking" component={RouterLink}>
                      Ranking
                    </ListItem> */}
                    {account ? (
                      <ListItem to="/activity" component={RouterLink}>
                        My Activity
                      </ListItem>
                    ) : (
                      <ListItem
                        onClick={() => {
                          history.push({
                            pathname: "conect-wallet",
                            search: "myactivity",
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        My Activity
                      </ListItem>
                    )}
                    <ListItem style={{ cursor: "pointer" }}>
                      <Link
                        onClick={() =>
                          window.open("https://referral.hovr.site/")
                        }
                      >
                        Referral Program
                      </Link>
                    </ListItem>
                    <ListItem to="/support-tickets" component={RouterLink}>
                      Support-Ticket
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6" color="primary">
                    Dashboard
                  </Typography>
                  <List>
                    {account && user?.userData?.userType === "Admin" ? (
                      <ListItem to="my-mints" component={RouterLink}>
                        My Mint
                      </ListItem>
                    ) : (
                      <ListItem
                        style={{ cursor: "pointer" }}
                      >
                        My Mint
                      </ListItem>
                    )}

                    {account ? (
                      <ListItem to="create" component={RouterLink}>
                        Create Items
                      </ListItem>
                    ) : (
                      <ListItem
                        style={{ cursor: "pointer" }}
                      >
                        Create Items
                      </ListItem>
                    )}
                    {account ? (
                      <ListItem to="create-nfttype" component={RouterLink}>
                        Create Physical NFT
                      </ListItem>
                    ) : (
                      <ListItem
                        style={{ cursor: "pointer" }}
                      >
                        Create Physical NFT
                      </ListItem>
                    )}
                    {account ? (
                      <ListItem to="profile" component={RouterLink}>
                        My Account
                      </ListItem>
                    ) : (
                      <ListItem
                        style={{ cursor: "pointer" }}
                      >
                        My Account
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6" color="primary">
                    Faqs
                  </Typography>
                  <List>
                    {/* <ListItem>
                      {history.location.pathname !== "/" ? (
                        <List>
                          <ListItem>
                            <Link
                              to="/"
                              style={{
                                textDecoration: "none",
                                color: "#aeaeae",
                              }}
                            >
                              FAQs{" "}
                            </Link>
                          </ListItem>
                        </List>
                      ) : (
                        <List>
                          <ListItem>
                            <ScrollLink
                              smooth={true}
                              duration={500}
                              style={{
                                textDecoration: "none ",
                                cursor: "pointer",
                              }}
                              to="section1"
                            >
                              FAQs{" "}
                            </ScrollLink>
                          </ListItem>
                        </List>
                      )}
                    </ListItem> */}
                    <ListItem to="/faqs" component={RouterLink}>
                      FAQs
                    </ListItem>
                    <ListItem to="/help-center" component={RouterLink}>
                      Help Center
                    </ListItem>
                    <ListItem to="/feedback" component={RouterLink}>
                      Feedback
                    </ListItem>
                    <ListItem to="/about" component={RouterLink}>
                      About Us
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6" color="primary">
                    Contact Us
                  </Typography>
                  <List>
                    <ListItem>
                      <Link href="mailto:info@hovr.site">
                        <AiOutlineMail />
                        {/* {user?.userData?.userType === "Admin" ? (
                      <> {user?.userData?.email}</>
                    ) : (
                      <>support@HOVR.com</>
                    )} */}
                        info@hovr.site
                      </Link>
                    </ListItem>
                  </List>
                  <Box>
                    <IconButton>
                      <Link
                        target="_blank"
                        href="https://www.facebook.com/hovr.site/"
                      >
                        <FaFacebookF />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        target="_blank"
                        href="https://www.youtube.com/watch?v=CMqRqd97rQg"
                      >
                        <FiYoutube />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        target="_blank"
                        href="https://www.instagram.com/hovr.site/?igshid=YmMyMTA2M2Y%3D"
                      >
                        <FaInstagram />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link target="_blank" href="https://twitter.com/HovrSite">
                        <FaXTwitter />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        target="_blank"
                        href="https://www.reddit.com/r/hovrNFT/"
                      >
                        <AiOutlineReddit style={{ fontSize: "20px" }} />
                      </Link>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box className="copy" mt={1}>
          <Container>
            <Box
              className="displaySpacebetween"
              position="relative"
              flexWrap="wrap"
            >
              <Box className="displayStart">
                <img
                  src="images/footer_line.png"
                  alt="images"
                  className="borderBox"
                />
                <List className="displayStart">
                  <ListItem
                    to="/terms-conditions"
                    component={RouterLink}
                    style={{ whiteSpace: "pre" }}
                  >
                    Terms & Conditions
                  </ListItem>

                  <ListItem
                    to="/privacy-policy"
                    component={RouterLink}
                    style={{ marginLeft: "30px" }}
                  >
                    Privacy Policy
                  </ListItem>
                </List>
              </Box>
              <Typography variant="body1" style={{ fontWeight: "200" }}>
                Copyright© 2024 Created with love by{" "}
                <span style={{ color: "#34B6FF", fontWeight: "bold" }}>
                  HOVR
                </span>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
