import { ACTIVE_NETWORK, NetworkDetails, RPC_URL } from "src/constants";
import { Contract } from "@ethersproject/contracts";
import Web3 from "web3";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

// export function sortAddress(add) {
//   const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
//   return sortAdd;
// }
export function convertTimestamp(timestamp) {
  if (timestamp) {
    // Convert to milliseconds if it is in seconds
    if (timestamp.toString().length === 10) {
      timestamp *= 1000; // Assuming it's in seconds, convert to milliseconds
    }
  }
  return timestamp;
}

export function downloadCSVFile(data, filename) {
  const workSheet = XLSX.utils.json_to_sheet(data);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "userList");
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${filename}`);
  // const csvContent =
  //   "data:text/csv;charset=utf-8," +
  //   data.map((row) => Object.values(row).join(",")).join("\n");

  // const encodedUri = encodeURI(csvContent);
  // const link = document.createElement("a");
  // link.setAttribute("href", encodedUri);
  // link.setAttribute("download", filename);
  // document.body.appendChild(link); // Required for Firefox
  // link.click();
  // document.body.removeChild(link);
}
export function processName(name, num) {
  const words = name.split(" ");

  if (words.length >= 2 && words[0].length < 6) {
    const shortenedName =
      words[0].length > 12 ? words[0].substring(0, 12) : words[0];
    const shortenedName2 =
      words[1].length > 12 ? words[1].substring(0, 6) + "..." : words[1];
    const result = `${shortenedName} ${shortenedName2}`;

    return result;
  } else {
    const result =
      words[0].length > num ? words[0].substring(0, num) + "..." : words[0];
    return result;
  }
}
export function sortAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
    return sortAdd;
  } else return add;
}
export function sortLinks(add) {
  if (add) {
    const sortAdd = add;
    // const sortAdd = `...${add.slice(add.length - 8)}`;
    return sortAdd;
  } else return add;
}
// export function sortAddressamount(add) {
//   if (add) {
//     const sortAdd = `${add?.slice(0, 6)}`;
//     return sortAdd;
//   } else return add;
// }
export const deadAddress = "0x0000000000000000000000000000000000000000";

export function firstAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...`;
    return sortAdd;
  } else return add;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export function validUrl(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}
export function validinsta(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}
export function validtwitter(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}
export function validtelegram(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}
export function validemailUrl(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}
export const getWeb3Provider = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  return httpProvider;
};

export const getWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress) => {
  const web3 = await getWeb3Obj();
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const getBalanceOf = async (abi, address, account) => {
  try {
    const contract = await getWeb3ContractObject(abi, address);
    const balanceOf = await contract.methods.balanceOf(account).call();
    return balanceOf.toString();
  } catch (error) {
    console.log("ERROR", error);

    return 0;
  }
};

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export function copyTextByID(id) {
  var copyText = document.getElementById(id);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};
