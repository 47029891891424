import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {
  Box,
  Typography,
  Container,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { sortAddress } from "src/utils";
import SearchBoxItem from "src/views/pages/Searchh/Index";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  MainTextFieldTheme: {
    maxWidth: "600px",
    width: "auto",
    marginTop: "22px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "33px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(230 226 230 / 0%)",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px !important",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px !important",
      padding: "10px",
    },
    "& .MuiIconButton-root": {
      background: theme.palette.background.blur,
      padding: "8px",
      marginLeft: "0px",
      BiBorderRadius: "10px",
      borderRadius: "10px",
      marginRight: "10px",
    },
  },
}));

export default function SearchBox({
  search,
  searchIcon,
  inputRoot,
  inputInput,
}) {
  const user = useContext(UserContext);
  const classes = useStyles();
  const searchTextRef = React.useRef(null);

  useEffect(() => {
    searchTextRef.current.focus();
  }, []);

  return (
    <div className={"searchField customSearch"}>
      <Container maxWidth="lg">
        <div className={search}>
          <Box className={classes.MainTextFieldTheme}>
            <TextField
              fullWidth
              variant="outlined"
              ref={searchTextRef}
              value={user.glbSearch}
              autoFocus={true}
              type="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => user.setGlbSearch(e.target.value)}
              placeholder="Search items and collections"
              classes={{
                root: inputRoot,
                input: inputInput,
              }}
            />
          </Box>
        </div>

        <SearchBoxItem />
      </Container>
    </div>
  );
}

export function SearchResults({ searchResult, history }) {
  return (
    <ul className="list-group text-dark" id="search-list">
      {searchResult?.userResult.length > 0 && (
        <>
          <li
            className="list-group-item"
            style={{ textAlign: "left", zIndex: 999 }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"}>
                <Typography variant="h6">User's</Typography>
              </Box>
            </Box>
          </li>
          {searchResult?.userResult.map((data, i) => {
            return (
              <li
                key={i}
                className="list-group-item"
                style={{ textAlign: "left", zIndex: 999 }}
                onClick={() => {
                  history.push({
                    pathname: "/author",
                    search: data._id,
                  });
                }}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    <img
                      src={
                        data.profilePic
                          ? data.profilePic
                          : "/images/onlycamimg.png"
                      }
                      alt=""
                    />
                    <Typography>
                      {" "}
                      {data?.userName
                        ? data?.userName
                        : data?.name
                        ? data?.name
                        : sortAddress(data?.walletAddress)}
                    </Typography>
                  </Box>
                </Box>
              </li>
            );
          })}
        </>
      )}
      {searchResult?.orderResult?.length > 0 && (
        <>
          <li
            className="list-group-item"
            style={{ textAlign: "left", zIndex: 999 }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"}>
                <Typography variant="h6">NFT's</Typography>
              </Box>
            </Box>
          </li>
          {searchResult?.orderResult.map((data, i) => {
            return (
              <li
                key={i}
                className="list-group-item"
                style={{ textAlign: "left", zIndex: 999 }}
                onClick={() => {
                  history.push({
                    pathname: "/nft",
                    search: data._id,
                  });
                }}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    <img src={data.nftId.coverImage} alt="" />
                    <Typography> {data.nftId.tokenName}</Typography>
                  </Box>
                </Box>
              </li>
            );
          })}
        </>
      )}
    </ul>
  );
}
