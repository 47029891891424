import React, { useContext, useEffect } from "react";
import { Box, Typography, makeStyles, Paper, Avatar } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  CollectioncardBox: {
    position: "relative",
    overflow: "hidden",
    "& .displaySpacebetween": {
      [theme.breakpoints.down("md")]: {
        // marginTop: "37px",
      },
    },
    "& .albumboxcard": {
      textAlign: "center",
      cursor: "pointer",
      overflow: "hidden",
      position: "relative",
      background: theme.palette.background.card,
      alignItems: "center",
      borderRadius: "11px",
      border: "1px solid #ffffff0f",
      "& label": {
        backgroundColor: "#f0b514",
        color: "#000",
        position: "absolute",
        height: "20px",
        width: "150px",
        transform: "rotate(319deg)",
        left: "-31px",
        top: " 25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "11px",
        zIndex: "11",
      },
      "& h6": {
        whiteSpace: "nowrap",
        width: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("sm")]: {
          fontSize: "13px !important",
        },
      },
      "& .hoverCircle": {
        position: "absolute",
        top: "0px",
        left: "92px",
        right: "0",
        zIndex: "1",
        borderRadius: "9%",
        width: "367px",
        height: "135px",
        background: "rgba(154, 70, 217, 0.43)",
        WebkitFilter: "blur(50px)",
        display: "none",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      "& .hoverCircleDarkClass": {
        background: "linear-gradient(180deg,rgb(59 21 64),#411248)",
        // background: "rgba(134, 32, 81, 0.56) !important",
      },
      "&:hover": {
        "& .hoverCircle": {
          display: "block",
        },
      },
      "& .lineBorder": {
        position: "absolute",
        top: "12px",
        left: "37%",
        width: "auto",
        maxWidth: "100%",
        zIndex: "9",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      "& .lineBorder1": {
        top: "-84px",
        left: "50%",
        width: "auto",
        zIndex: "9",
        position: "absolute",
        maxWidth: "100%",
        transform: "rotate(90deg)",
      },
    },
    "& .avatarimg": {
      width: "157px",
      height: "157px",
      borderRadius: "10px",
      objectFit: "contain",
      background: "#fff",
      zIndex: "9",
      [theme.breakpoints.down("sm")]: {
        width: "90px",
        height: "90px",
      },
    },
    "& .profileImagge": {
      width: "65px",
      height: "65px",
      objectFit: "cover",
      marginLeft: "8px",
      background: "#fff",
      zIndex: "9",
    },
    "& .displayAlignStart": {
      marginLeft: "24px",
      [theme.breakpoints.only("xs")]: {
        marginLeft: "7px",
      },
    },
  },
  mainimg: {
    width: "100%",
    height: "190px ",
    overflow: "hidden",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: " no-repeat !important",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ccc !important",
  },
}));
export default function HotCollectionCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const themesetting = useContext(SettingsContext);
  const { data, type } = props;
  const updateDimensions = () => {
    var offsetWidth = document.getElementById(
      "imagecard" + data?._id
    ).offsetWidth;
    var newoofsetWidth = offsetWidth - 80;
    document.getElementById("imagecard" + data?._id).style.height =
      newoofsetWidth + "px";
  };
  useEffect(() => {
    updateDimensions();
  }, [data, data?._id]);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Box className={classes.CollectioncardBox}>
      <Box
        className="albumboxcard"
        display={type === "collectionCard" ? "block" : "flex"}
        style={
          type === "collectionCard"
            ? { padding: "10px" }
            : {
                width: "calc(100% - 50px)",
                margin: "10px",
                padding: "15px",
              }
        }
      >
        {data?.isPromoted && <label>Promoted</label>}
        <Box alignItems="center">
          <Box
            id={`imagecard${data?._id}`}
            className={classes.mainimg}
            style={
              data?.bannerImage
                ? {
                    background: "url(" + data?.bannerImage + ")",
                    borderRadius: "10px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundColor: "#fff",
                  }
                : { background: "url(" + "images/market_detail.png" + ")" }
            }
            onClick={() => {
              history.push({
                pathname: "/collection-details",
                search: data?._id,
                state: {
                  data: data,
                },
              });
            }}
          ></Box>

          <Box
            style={type === "collectionCard" ? { position: "relative" } : {}}
          >
            <img
              src="images/line.png"
              alt="image"
              className={
                type === "collectionCard" ? "lineBorder1" : "lineBorder"
              }
            />
            {/* <Typography variant="h6" align="center" color="primary">
              {" "}
              {data?.displayName}{" "}
            </Typography> */}
            <Box
              className={
                type === "collectionCard"
                  ? "displayCenter"
                  : "displayAlignStart"
              }
              style={type === "collectionCard" ? { marginTop: "35px" } : {}}
            >
              <Avatar
                style={type === "collectionCard" ? { margin: "0px" } : {}}
                onClick={() => {
                  history.push({
                    pathname: "/collection-details",
                    search: data?._id,
                    state: {
                      data: data,
                    },
                  });
                }}
                src={
                  data?.collectionImage
                    ? data?.collectionImage
                    : "/images/avaterimg.png"
                }
                alt="user"
                className="profileImagge"
              />
              <Box ml={2} position="relative" zIndex="9" align="center">
                <Typography variant="h6" align="center" color="primary">
                  {" "}
                  {data?.displayName}{" "}
                </Typography>
                <Box align="center" mt={1}>
                  <img src="images/qi.svg" alt="Vector Image" width="30px" />

                  {data?.price}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
