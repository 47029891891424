import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import SettingsContext from "src/context/SettingsContext";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    backgroundRepeat: "repeat",
    background: "#fff",
    backgroundImage: "url(/images/light.jpg)" /* fallback */,
    backgroundSize: "100%",
    backgroundPosition: "top",
  },
  root1: {
    backgroundColor: "#000",
    backgroundRepeat: "repeat",
    background: "#000",
    // backgroundImage: "url(/images/dark.jpg)" /* fallback */,
    backgroundImage: "url(/images/newdark_final.png)",
    backgroundSize: "100%",
    backgroundPosition: "top",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // paddingTop: 70,
    padding: "130px 20px 20px 20px",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 290,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "90px 20px 20px 20px",
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    // backgroundColor: "rgba(204, 204, 204, 0)",
    // backgroundImage: "url(/images/line.png)" /* fallback */,
    backgroundSize: "100%",
    // background: "#F4FCFA",
    backgroundPosition: "top",
    // backgroundImage:
    //   " url(/images/line.png), linear-gradient(105deg, #feeefd 1.25%, #4606b9 99.18%)" /* W3C */,
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    // padding: "10px 50px 0px ",
    [theme.breakpoints.down("sm")]: {
      // padding: "10px 20px 0px ",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  return (
    <div>
      {/* <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} /> */}
      <div
        className={
          themeSeeting.settings.theme === "DARK"
            ? `${classes.root1}`
            : `${classes.root}`
        }
      >
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="main-scroll">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
