import React, { useContext, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { UserContext } from "src/context/User";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
    "& .active": {
      background: "linear-gradient(271.12deg, #F73889 -50%, #9038FF 90.79%)",
      color: "#fff",
      borderRadius: "12px",
      marginBottom: "5px",
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& $icon": {
        color: "00e0b0",
      },
    },
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "24px",
    paddingRight: " 24px",
  },
  button: {
    color: "#707070",
    padding: "10px 0",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    // color: "#f5f7fac9",
    padding: "13px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "300",
    borderRadius: 0,
    marginBottom: "5px",
    fontSize: "12px",
    color: theme.palette.text.gray,
    "&:hover": {
      background: "linear-gradient(271.12deg, #F73889 -50%, #9038FF 90.79%)",
      color: "#fff",
      borderRadius: "10px",
      marginBottom: "5px",
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  DisplayNone: {
    display: "none",
  },
  active: {
    background: "linear-gradient(271.12deg, #F73889 -50%, #9038FF 90.79%)",
    color: "#fff",
    borderRadius: "12px",
    marginBottom: "5px",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={{ color: "#ccc" }}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(
        classes.itemLeaf,
        className,
        href === "/social" &&
          user?.userData?.influencerStatus !== "APPROVED" &&
          classes.DisplayNone
      )}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        className={clsx(
          classes.buttonLeaf,
          window.location.pathname == href && classes.active,
          `depth-${depth}}`
        )}
        exact
        style={style}
        onClick={() =>
          href === "/social"
            ? user?.userData?.influencer &&
              user?.userData?.influencerStatus === "APPROVED"
              ? history.push(`${href}`)
              : toast.warn(
                  "Please verify yourself from your profile to access influencer feature."
                )
            : history.push(`${href}`)
        }
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
