import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  InputBase,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Typography,
  DialogContent,
  InputAdornment,
  Dialog,
  FormControl,
  Paper,
  Avatar,
  Hidden,
} from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import CloseIcon from "@material-ui/icons/Close";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState, useEffect, useRef, useContext } from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";
import ConnectWallet from "src/views/pages/Connect/ConnectWallet";
import { processName, sortAddress } from "src/utils";
import { CgSearch } from "react-icons/cg";
import { GiCancel } from "react-icons/gi";
import { useWeb3React } from "@web3-react/core";
import { useLocation } from "react-router-dom";
import ApiConfig from "src/ApiConfig/ApiConfig";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import SearchResultBox from "src/views/pages/Search";
import SearchBox from "../SearchBox";
import Logo from "src/component/Logo";
const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    color: theme.palette.primary.main,
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: theme.palette.secondary.dark,
    },
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
  menuButton1: {
    width: "100%",
  },
  toolbar: {
    border: "2px solid rgba(0, 0, 0, 0.025)",
    display: "flex",
    padding: "12px 33px",
    // margin: "-25px 20px 0px 33px",
    margin: "18px 17px 0px 18px",
    background: "rgba(255, 255, 255, 0.04)",
    marginTop: "20px",
    alignItems: "center",
    borderRadius: "15px",
    backdropFilter: "blur(4px)",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "0px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  flexButton: {
    display: "flex",
    justifyContent: "flex-between",
    alignItems: "center",
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "35px",
    position: "relative",
    background: " rgba(255, 255, 255, 0.2)",
    boxShadow: "0px 6px 9px rgba(110, 0, 149, 0.15)",
    borderRadius: "40px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
    marginLeft: 10,
    marginRight: 10,
    width: "150px",
    maxWidth: "150px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "204px",
      maxWidth: "204px",
      height: "42px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 1),
    color: "#fff",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#fff",
    fontSize: "12px",
    width: "100%",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    color: "#fff",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    height: "20px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      width: "100%",
      height: "25px",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  menuMobile1: {
    marginLeft: "10px",
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 5px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px !important",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
      },
    },
  },
  customizedButton: {
    fontSize: "20px",
    padding: "5px 10px 10px 0px",
  },
  dailogOpen: {
    borderRadius: "25px",
    padding: "10px",
    "& h5": {
      color: "#3B0D60",
      fontSize: "17px",
    },
  },
  customizedButton1: {
    display: "flex !important",
    justifyContent: "end",
    "& div": {
      display: "flex !important",
    },
  },
  paper: {
    overflowY: "unset",
  },
  dialogBox: {
    padding: "20px !important",
    "& h5": {
      color: "#3B0D60",
      fontSize: "20px",
    },
  },
  searcBox: {
    height: "50px",
    "& .MuiIconButton-root": {
      background: theme.palette.background.blur,
      marginLeft: "-6px",
      padding: "8px",
    },
  },
  menuMobiledrawer: {
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      whiteSpace: "pre",
      fontWeight: "400",
    },
  },
  mainTextfieldBox: {
    maxWidth: "600px",
    width: "auto",
    marginTop: "22px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "22px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(230 226 230 / 0%)",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px !important",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px !important",
    },
    "& .MuiIconButton-root": {
      background: theme.palette.background.blur,
      marginLeft: "-6px",
      padding: "8px",
    },
  },
  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [walletPopup, setWalletPopup] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose5 = () => {
    setAnchorEl2(null);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    paper,
    dialogBox,
    divstake,
    toolbar,
    search,
    searchIcon,
    flexButton,
    inputInput,
    drawerContainer,
    drawericon,
    inputRoot,
    logoDrawer,
    containerHeight,
    mainHeader,
    searcBox,
    wallet,
    menuMobile1,
    submenu,
    customizedButton,
    dailogOpen,
    customizedButton1,
    menuMobiledrawer,
  } = useStyles();

  useEffect(() => {
    if (Boolean(anchorEl2)) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [anchorEl2]);
  useEffect(() => {
    if (Boolean(anchorEl1)) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [anchorEl1]);
  const { mobileView, drawerOpen } = state;
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false);
  const [updateName, setUpdateName] = useState(false);
  const searchTextRef = React.useRef(null);
  const location = useLocation();
  const [searchResult, setSearchResult] = useState();

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }
  const [searchInput, setSearchInput] = useState("");

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      if (searchInput !== "") {
        history.push({
          search: searchInput,
        });
      } else {
        setSearchInput("");
        history.push({
          search: searchInput,
        });
      }
    }
  };

  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  useEffect(() => {
    if (searchInput) {
    } else {
      setSearchResult();
    }
    return () => {};
  }, [searchInput]);
  const [isValidProfile, setisValidProfile] = useState(false);
  function isImageUrlValid(url) {
    const img = new Image();
    img.onload = function () {
      setisValidProfile(true);
    };
    img.onerror = function () {
      setisValidProfile(false);
    };
    img.src = url;
  }
  useEffect(() => {
    isImageUrlValid(user.userData?.profilePic);
  }, [user.userData?.profilePic]);
  useEffect(() => {
    searchTextRef.current.focus();
    if (
      location.pathname === "/searchprofile" &&
      location.search &&
      location.search.slice(1, location.search.length)
    ) {
      let text = location.search.slice(1, location.search.length);
      setSearchInput(text);
    }
  }, [location]);

  const displayDesktop = () => {
    return (
      <Box>
        <Toolbar className={toolbar}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {/* {femmecubatorLogo} */}
            <Link to="/">
              <Logo className="logoImg" />
            </Link>
            <div className={flexButton}>
              <FormControl
                variant="outlined"
                className={searcBox}
                style={{ display: "none" }}
              >
                <InputBase
                  placeholder="Search"
                  ref={searchTextRef}
                  autoFocus={true}
                  type="search"
                  onKeyDown={onKeyDown}
                  onChange={(e) => setSearchInput(e.target.value)}
                  id="outlined-adornment-weight"
                  startAdornment={
                    <InputAdornment position="start">
                      <CgSearch
                        style={{ fontSize: "25px", marginLeft: "10px" }}
                      />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                />
              </FormControl>

              <Box>
              { user?.isLogin &&  <div style={{ margin: "0px 0px 0px 10px" }}>
                  <Button
                    variant="text"
                    color="primary"
                    aria-label="delete"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick2}
                    className={menuMobiledrawer}
                    size="small"
                    style={{
                      margin: "0 15px",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Add Your Brand
                    <BsFillCaretDownFill
                      style={{ fontSize: "16px", marginLeft: "8px" }}
                    />
                  </Button>
                </div>}
                <div>
                  <Box className={divstake}>
                    <StyledMenu
                      id="simple-menu"
                      disableScrollLock={true}
                      anchorEl={anchorEl2}
                      keepMounted
                      open={Boolean(anchorEl2)}
                      onClose={handleClose5}
                    >
                      <MenuItem
                        onClick={() => {
                          if (user?.isLogin) {
                            history.push("/add-brand");
                          } else {
                            history.push({
                              pathname: "/conect-wallet",
                              search: "add-brand",
                            });
                          }
                        }}
                      >
                        Add Your Brand
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          history.push("/brandlist");
                        }}
                      >
                        Browse Brand
                      </MenuItem>
                    </StyledMenu>
                  </Box>
                </div>
              </Box>

              {stackmenu}
              <Box className="themeButton" style={{ margin: "0 20px" }}>
                {themeSeeting.settings.theme === "DARK" ? (
                  <IconButton
                    variant="contained"
                    color="primary"
                    style={{ background: "#FFFFFF0D" }}
                    onClick={() => {
                      changeTheme("LIGHT");
                    }}
                  >
                    <FiSun />
                  </IconButton>
                ) : (
                  <IconButton
                    variant="contained"
                    color="primary"
                    style={{ background: "#FFFFFF0D" }}
                    onClick={() => {
                      changeTheme("DARK");
                    }}
                  >
                    <FaRegMoon />
                  </IconButton>
                )}
              </Box>
              <Box style={{ display: "flex" }}>
                <IconButton
                  onClick={() => setDialogOpen(true)}
                  style={{ background: "#FFFFFF0D" }}
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            </div>
          </Grid>
        </Toolbar>
      </Box>
    );
  };

  const displayMobile = () => {
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/footerlogo.png" alt="" />
            {stackmenu}
          </div>
        </Drawer>

        <div style={{ marginLeft: "20px" }}>{femmecubatorLogo}</div>
        <Grid container spacing={1}>
          <Grid item xs={10} sm={11}>
            <Box className="displayEnd">
              {stackmenu}{" "}
              <Box className="themeButton">
                {themeSeeting.settings.theme === "DARK" ? (
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      changeTheme("LIGHT");
                    }}
                  >
                    <FiSun />
                  </IconButton>
                ) : (
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      changeTheme("DARK");
                    }}
                  >
                    <FaRegMoon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">{/* <Logo className="logoImg" /> */}</Link>
    </Box>
  );
  const stackmenu = (
    <div>
      {user?.isLogin ? (
        <Button
          aria-label="delete"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick1}
          className={menuMobile1}
          size="small"
          color="primary"
        >
          <Avatar
            type="profile"
            src={isValidProfile && user.userData?.profilePic}
            alt=""
            style={
              user?.isLogin
                ? { height: "40px", width: "40px" }
                : { width: "0px" }
            }
          />
          <Typography
            variant="h4"
            color="primary"
            title={
              user?.userData?.name
                ? user?.userData?.name
                : user?.userData?.walletAddress
            }
          >
            {" "}
            {user?.userData?.name
              ? processName(user?.userData?.name, 10)
              : sortAddress(user?.userData?.walletAddress)}
          </Typography>
          <BsFillCaretDownFill
            style={{ fontSize: "16px", marginLeft: "8px" }}
          />
        </Button>
      ) : (
        <IconButton
          aria-label="delete"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => setUpdateMinSatkeOpen(true)}
          className={menuMobile1}
          size="small"
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            to="/conect-wallet"
            component={Link}
          >
            Connect
          </Button>
        </IconButton>
      )}
      <Box className={divstake}>
        <StyledMenu
          id="simple-menu"
          disableScrollLock={true}
          anchorEl={anchorEl1}
          keepMounted
          open={Boolean(anchorEl1)}
          onClose={handleClose4}
        >
          {user?.kycStatusRes?.kycStatus !== "APPROVE" && user?.isAdmin && (
            <MenuItem
              onClick={() => {
                history.push("/become-creator");
              }}
            >
              Become a creator
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              history.push("/profile");
            }}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/create");
            }}
          >
            Create NFT
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/create-nfttype");
            }}
          >
            Create Physical NFT
          </MenuItem>
          <MenuItem
            onClick={() => {
              user.logoutHandler();
              setAnchorEl1();
            }}
          >
            Disconnect
          </MenuItem>
        </StyledMenu>
      </Box>
    </div>
  );

  return (
    <>
      {updateName && (
        <Dialog
          open={updateName}
          onClose={() => {
            setUpdateName(false);
          }}
          maxWidth="sm"
        ></Dialog>
      )}

      {updateMinSatkeOpen && (
        <Dialog
          open={updateMinSatkeOpen}
          onClose={() => {
            setUpdateMinSatkeOpen(false);
          }}
          maxWidth="sm"
        >
          <DialogContent>
            <ConnectWallet
              onClose={() => {
                setUpdateMinSatkeOpen(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>
      <Box>
        {dialogOpen && (
          <Paper>
            <Dialog
              fullWidth
              maxWidth="lg"
              className={classes.searchdiaogBox}
              style={{
                position: "absolute",
                top: "10%",
                // minHeight: "695px",
              }}
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
            >
              <IconButton
                className="closeButton"
                onClick={() => setDialogOpen(false)}
              >
                <CloseIcon style={{ color: "#AAAAAA" }} />
              </IconButton>

              <Box className="dialogBoxHeight">
                <SearchBox />
              </Box>
            </Dialog>
          </Paper>
        )}

        {walletPopup && (
          <Dialog
            open={walletPopup}
            onClose={() => setWalletPopup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            className={dailogOpen}
            style={{ borderRadius: "25px", padding: "10px" }}
          >
            <DialogContent
              style={{
                width: "500px",
                background: "#fff",
                borderRadius: "25px",
                padding: "10px !important",
              }}
            >
              <Box
                className={customizedButton1}
                style={{ display: "flex !important" }}
              >
                <IconButton
                  onClick={() => setWalletPopup(false)}
                  className={customizedButton}
                >
                  <GiCancel />
                </IconButton>
              </Box>
              <Box mb={2}>
                <Typography variant="h5">
                  We are required by law to verify content creators in our
                  platform due to the nature of our business. Please verify
                  below in order to become a creator.
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/become-creator")}
                >
                  Verify
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </>
  );
}
