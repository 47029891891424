import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import ApiConfig from "src/ApiConfig/ApiConfig";
import * as yep from "yup";
import { Formik, ErrorMessage, Form } from "formik";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  StayInTouchBox: {
    padding: "40px 0px 20px",
    background: "url('images/stay_banner.png')",
    position: "relative",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundSize: "cover",
    zIndex: "1",
    "&:before": {
      top: "0",
      left: "0px",
      width: "100%",
      height: "100%",
      content: "' '",
      position: "absolute",
      background: "rgba(0, 0, 0, 0.4)",
      zIndex: "-1",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "60px 0px",
    },
    "& .shape": {
      position: "absolute",
      top: "70px",
      left: "100px",
    },
    "& .shape1": {
      position: "absolute",
      top: "60px",
      left: "180px",
    },
    "& .shape2": {
      position: "absolute",
      bottom: "-25px",
      left: "44%",
    },
    "& .shape3": {
      right: "0px",
      bottom: 0,
      position: "absolute",
      width: "65px",
    },
    "& .shape4": {
      position: "absolute",
      right: "15%",
      top: "25px",
    },
    "& .shape5": {
      position: "absolute",
      right: "8%",
      bottom: "25px",
    },
    "& .emailBox": {
      // display: "flex",
      "& .MuiOutlinedInput-input": {
        color: "#fff !important",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        "-webkit-text-fill-color": "#fff !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "#fff !important",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      "-webkit-text-fill-color": "#fff !important",
    },
    "& input": {
      backgroundColor: "rgba(217, 217, 217, 0.05)",
      borderRadius: "10px",
      border: " none",
      height: "50px",
      padding: "0 15px",
    },
    "& h1": {
      color: "#ffffff",
      fontFamily: "'ClashDisplay-Extralight'",

      "& span": { fontFamily: "'ClashDisplay-Medium'" },
    },
    "& p": {
      color: "rgba(255, 255, 255, 0.6)",
      // maxWidth: "300px",
      // padding: "15px 0px",
    },
  },
  searchBtn: {
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "10px",
    height: "50px",
    // padding: "0 45px",
    marginLeft: "-7px",
    color: "#ffffff",
    [theme.breakpoints.only("xs")]: {
      padding: "0 25px",

      fontSize: "12px !important",
    },
  },
}));

export default function BestSeller(props) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState(false);
  const [captcha, setCaptcha] = useState();
  console.log("bchjgcdsgcdsu", captcha);

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(5);
  }, []);

  const formInitialSchema = {
    email: "",
    link: "",
    captcha: "",
  };

  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .required("Email address is required field.")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid Email address"
      ),
    captcha: yep.string().required("Please fill captcha"),
  });

  const handleFormSubmit = async (values, resetForm) => {
    if (validateCaptcha(values.captcha) === true) {
      try {
        axios({
          method: "POST",
          url: ApiConfig.userSubscribe,

          params: {
            email: values.email,
            // link: "http://localhost:3000/subscribehovr"
            link: "https://hovr.site/subscribehovr",
            // link: "http://full-blockchain.mobiloitte.org/subscribehovr",
          },
        })
          .then(async (response) => {
            if (response.data.statusCode === 200) {
              resetForm({ values: "" });
              toast.success("We have sent an email. please verify");

              setIsUpdating(false);
            } else if (response.statusCode === 409) {
              toast.success(response.data.responseMessage);
              setIsUpdating(false);
            } else {
              setIsUpdating(false);
              toast.success("We have sent email please verify");
              setConfirmation(true);
            }
          })
          .catch((error) => {
            console.log(error.message);
            if (error.response) {
              toast.error(error.response.data.responseMessage);
            }
            setIsUpdating(false);
          });
        // }
      } catch (err) {
        console.error(err.response);
        //  setIsLoading(false);
      }
    } else {
      toast.warn("Please fill the valid capcha");
    }
  };

  return (
    <Box className={classes.StayInTouchBox} align="center">
      <Container maxWidth="sm">
        <Typography variant="h1">
          Stay In <span>Touch</span>
        </Typography>
        <Typography variant="body2">
          Get Notified about important socical Updates.
        </Typography>

        <Box pt={2} pb={2}>
          <Formik
            initialValues={formInitialSchema}
            initialStatus={{
              success: false,
              successMsg: "",
            }}
            validationSchema={formValidationSchema}
            onSubmit={(values, { resetForm }) =>
              handleFormSubmit(values, resetForm)
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box className="emailBox">
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          name="email"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="outlined-basic"
                          placeholder="Enter your email"
                          fullWidth
                          style={{ color: "#000" }}
                        />
                        <FormHelperText
                          error
                          style={{ color: "rgb(239 18 18)" }}
                        >
                          {touched.email && errors.email}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <Box style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "11px",
                        }}
                      >
                        <LoadCanvasTemplateNoReload />
                      </div>

                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          id="captcha"
                          value={values.captcha}
                          error={Boolean(touched.captcha && errors.captcha)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="captcha"
                          type="text"
                          placeholder="Enter Captcha Value"
                          fullWidth
                          style={{ color: "#000" }}
                        />
                        <FormHelperText
                          error
                          style={{ color: "rgb(239 18 18)" }}
                        >
                          {touched.captcha && errors.captcha}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      type="submit"
                      className={classes.searchBtn}
                      disabled={isUpdating}
                      variant="contained"
                      color="primary"
                    >
                      Subscribe{isUpdating && <ButtonCircularProgress />}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
}
