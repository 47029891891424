import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiTable: {
      root: {
        borderSpacing: "0px 13px",
        borderCollapse: "separate",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px !important",
        // color: "rgb(255, 125, 104) !important",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
      },
    },
    // MuiFormHelperText: {
    //   root: {
    //     "& .Mui-error": {
    //       color: "rgb(255, 125, 104) !important",
    //       marginLeft: "0px !important",
    //     },
    //   },
    // },
    MuiPickerDTToolbar: { toolbar: { borderRadius: "8px 8px 0px 0px" } },
    MuiButton: {
      root: {
        "&.Mui-disabled": {
          color: "rgb(112, 107, 107)",
        },
      },
      contained: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "10px",
        whiteSpace: "pre",
        padding: "12px 30px",
      },
      outlined: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "10px",
        whiteSpace: "pre",
        padding: "12px 30px",
      },
      containedSizeLarge: {},
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#000",
        },
      },
      MuiSelect: {
        icon: {
          color: "#000",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#ffffff",
        },
      },
      MuiDialogActions: {
        root: {
          background: "rgba(0, 0, 0, 0.03)",
          padding: "19px",
          borderRadius: "8px 8px 0 0",
          justifyContent: "center",
        }
      },
      MuiDialog: {
        paper: {
          margin: "32px",
          position: "relative",
          overflowY: "inherit",
          color: "#fff !important",
          border: "solid 2px transparent !important",
          borderRadius: "10px !important",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #4ddaffc4, #0c2745)",
          backgroundOrigin: "border-box",
          backgroundClip: "content-box, border-box",
          boxShadow: "2px 1000px 1px #FCFDFF inset",
        },
      },
      MuiInputBase: {
        root: {
          color: "#000",
          background: "rgba(0, 0, 0, 0.04)",
          borderRadius: "50px",
          // height: "50px !important",
        },
      },
      MuiTableCell: {
        head: {
          color: "#000",
          fontWeight: "300",
        },
        stickyHeader: {
          top: "0",
          left: "0",
          zIndex: "2",
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          boxShadow: "rgb(99 99 85 / 38%) 0px 0px 3px 0px",
        },
        body: {
          backgroundColor: "rgba(0, 0, 0, 0.04)",

          boxShadow: "rgb(99 99 85 / 38%) 0px 0px 3px 0px",
        },
      },
      MuiButton: {
        containedPrimary: {
          backgroundColor: "rgba(0, 0, 0, 1)",
          color: "#fff",
          "&:hover": {
            boxShadow: "none !important",
            backgroundColor: "#51ACED !important",
          },
        },
        containedSecondary: {
          backgroundColor: "#eeeeee",
          border: "1px solid #27282a",
          color: "#000 !important",
          "&:hover": {
            boxShadow: "none !important",
            backgroundColor: "#51ACED !important",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.03) ",
          },
        },
        outlinedPrimary: {
          color: "#000",
          border: "1px solid #000 !important",
          "&:hover": {
            color: "#fff",
            boxShadow: "none !important",
            backgroundColor: "#51ACED !important",
            // border: "1px solid #51ACED !important",
          },
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: { color: "#000" },
      },
      MuiPickersClockNumber: { clockNumber: { color: "#000" } },
      MuiPickersDay: {
        day: {
          color: "#000",
        },
      },
      MuiPaginationItem: {
        root: {
          color: "#000",
        },
      },
      MuiPaper: {
        root: { color: "#000" },
        elevation2: {
          padding: "15px",
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          borderRadius: 10,
          overflow: "hidden",
          boxShadow: "none",
        },
      },
      MuiIconButton: {
        root: {
          color: "#000000",
        },
      },

      MuiOutlinedInput: {
        inputMultiline: {
          padding: "1px !important",
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
            boxShadow: "none",
          },
        },
        notchedOutline: {
          background: "rgba(0, 0, 0, 0.07)",
          borderColor: "rgb(230 226 230)",
        },
        input: {
          borderRadius: "10px",
          color: "#000",
          padding: "16px 14px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#000",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
    },
    typography: {
      // fontFamily: "'K2D', sans-serif"
    },
    palette: {
      background: {
        sellercard: "#fff",
        default: "#cccccc",
        blur: "rgba(0, 0, 0, 0.03);",
        card: "#F7F7F7",
        form: "rgba(0, 0, 0, 0.03)",
        black: "#ffffff",
        tab: "#000000",
        gray: "#cccccc59",
        tabback: "#000",
        proback: "#fff",
      },
      primary: {
        main: "#000000", //black
      },
      secondary: {
        main: "#000000", //black
        icons: "#009900", //white
      },
      text: {
        primary: "#ffffff", //black
        secondary: "#000", //white
        gray: "#000",
      },
    },
  },
  {
    name: "DARK",
    overrides: {
      MuiSvgIcon: {
        root: {
          color: "#fff",
        },
      },
      MuiSelect: {
        icon: {
          color: "#fff",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#191919",
        },
      },
      MuiDialog: {
        paper: {
          margin: "32px",
          position: "relative",
          overflowY: "inherit",
          color: "#fff !important",
          border: "1px solid #323232",
          borderRadius: "10px !important",
          background: "#222222",
          backgroundOrigin: "border-box",
          backgroundClip: "content-box, border-box",

        },
      },
      MuiDialogActions: {
        root: {
          background: "rgb(45, 45, 45)",
          padding: "19px",
          borderRadius: "8px 8px 0 0",
          justifyContent: "center",
        }
      },
      MuiPickersDay: {
        day: {
          color: "#fff",
        },
      },
      MuiInputBase: {
        root: {
          color: "#FFFFFF",
          background: "rgba(255, 255, 255, 0.03)",
          borderRadius: "50px",
          // height: "50px !important",
        },
      },
      MuiTableCell: {
        head: {
          color: "#ffffff",
          fontWeight: "300",
        },
        stickyHeader: {
          top: "0",
          left: "0",
          zIndex: "2",
          position: "relative",
          backgroundColor: "#141414",
          boxShadow: "rgb(99 99 85 / 38%) 0px 0px 3px 0px",
        },
        body: {
          backgroundColor: "#141414",
          boxShadow: "rgb(99 99 85 / 38%) 0px 0px 3px 0px",
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: { color: "#fff" },
      },
      MuiButton: {
        containedPrimary: {
          color: "#fff",
          backgroundColor: "rgba(255, 255, 255, 0.1)",

          "&:hover": {
            boxShadow: "none !important",
            backgroundColor: "#51ACED !important",
          },
        },
        containedSecondary: {
          backgroundColor: "#111111",
          color: "#fff",
          "&:hover": {
            boxShadow: "none !important",
            backgroundColor: "#51ACED !important",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.025) ",
            color: "#ffffff45",
          },
        },
        outlinedPrimary: {
          border: "1px solid rgba(255, 255, 255, 0.1) !important",
          "&:hover": {
            color: "#fff",
            boxShadow: "none !important",
            backgroundColor: "#51ACED !important",
            // border: "2px solid #51ACED !important",
          },
        },
      },
      MuiPaginationItem: {
        root: {
          color: "#ffffff",
        }
      },
      MuiPaper: {
        root: {
          color: "#fff",
          backgroundColor: "rgba(255, 255, 255, 0.03)",
          // backdropFilter: "blur(40px)",
        },

        elevation2: {
          padding: "15px",
          backgroundColor: "rgba(255, 255, 255, 0.025)",
          borderRadius: 10,
          overflow: "hidden",
          boxShadow: "none",
        },
      },
      MuiIconButton: {
        root: {
          color: "#fff",
        },
      },
      MuiOutlinedInput: {
        inputMultiline: {
          padding: "1px !important",
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
            boxShadow: "none",
          },
        },
        notchedOutline: {
          background: "rgba(255, 255, 255, 0.025)",
          borderColor: "rgba(255, 255, 255, 0.025)",
        },
        input: {
          borderRadius: "10px",
          color: "#fff",
          padding: "16px 14px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#fff",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
    },
    typography: {
      fontFamily: "'K2D', sans-serif",
    },
    palette: {
      background: {
        sellercard: "#000000",
        default: "#000000",
        blur: "rgba(255, 255, 255, 0.04);",
        card: "rgba(255, 255, 255, 0.03);",
        form: "rgba(255, 255, 255, 0.025)",
        black: "#000",
        tab: "rgba(255, 255, 255, 0.1)",
        gray: "#323232",
        tabback: "#FFFFFF1A",
        proback: "#222222",
      },
      primary: {
        main: "#ffffff", //black
      },
      secondary: {
        main: "#FFFFFF", //white
        icons: "#FFFFFF", //white
      },
      text: {
        primary: "#000", //white
        secondary: "rgba(255, 255, 255, 0.6)", //white
        gray: "rgba(255, 255, 255, 0.6)",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
