import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import SettingsContext from "src/context/SettingsContext";

export default function SearchDatanotfound({ data }) {
    const themeSeeting = useContext(SettingsContext);
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "30dvh",
                textAlign: "center",
            }}
        >
            <img
                src={
                    themeSeeting.settings.theme === "DARK"
                        ? "./images/dataNotFound1.png"
                        : "./images/dataNotFound.png"
                }
                alt="Data Not Found"
                style={{
                    width: "min(100% - 31px , 189px)",
                    height: "min(100% - 30px,70px)",
                }}
            />
            <Typography color="primary" variant="h6" style={{marginTop:"30px"}}>
                {data ? data : "No item found for the term search"}
            </Typography>
        </Box>
    );
}
