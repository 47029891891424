import React from "react";
import {
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { makeStyles } from "@material-ui/styles";
import { MdEmail } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  customizedButton:{
position:"absolute",
right:"0"
  },
  ShareSocialMedia: {
    "& p": {
      fontWeight: "300",
      color: theme.palette.text.gray,
    },
    "& .socialBox": {
      border: "1px solid #80808042",
      borderRadius: "10px",
      padding: "16px",
      marginBottom: "10px",
      [theme.breakpoints.only("xs")]: {
        padding: "6px",
      },

      "& .iconShareButton": {
        background: "transparent",
      },

      "&:hover": {
        background: "rgba(255, 255, 255, 0.1)",
        "& .iconShareButton": {
          // background: "#5D5D5D",
          background: theme.palette.background.blur,
        },
      },
    },
  },
  dialogactionmediabox:{
    // background: "rgb(45, 45, 45)",
    background: theme.palette.background.blur,
    borderRadius: "8px 8px 0 0",
    padding: "19px",
    justifyContent: "center",
  },
}));
export default function ShareSocialMedia({ url, onClose , subject = "Check out this item on Hovr"}) {
  const classes = useStyles();
  return (
    <>
      <DialogActions
      className={classes.dialogactionmediabox}
      
      >
        <Typography variant="h6" color="primary" style={{ textAlign: "center", fontSize:"20px" }}>
          Share link to this page
        </Typography>
        <IconButton onClick={onClose} className={classes.customizedButton}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Box className={classes.ShareSocialMedia} mb={3} align="center" mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Box className="socialBox">
                <FacebookShareButton url={url} target="_blank">
                  <IconButton className="iconShareButton">
                    {" "}
                    <FaFacebookF style={{ fontSize: "23px" }} />
                  </IconButton>
                </FacebookShareButton>
              </Box>
              <Typography variant="body2" color="primary">
                Facebook
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box className="socialBox">
                <EmailShareButton
                  url={url}
                  email="info@hovr.site"
                  // subject="Check out this item on Hovr"
                  subject={subject}
                  className="Demo_some-network__share-button"
                >
                  <IconButton className="iconShareButton">
                    <MdEmail style={{ fontSize: "23px" }} />
                  </IconButton>
                </EmailShareButton>
              </Box>
              <Typography variant="body2" color="primary">
                {" "}
                E-mail
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box className="socialBox">
                <TelegramShareButton
                  url={url}
                  title={subject}
                >
                  <IconButton className="iconShareButton">
                    <FaTelegramPlane style={{ fontSize: "23px" }} />
                  </IconButton>
                </TelegramShareButton>
              </Box>
              <Typography variant="body2" color="primary">
                {" "}
                Telegram
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box className="socialBox">
                <TwitterShareButton
                  url={url}
                  title={subject}
                  // hashtag="#camperstribe"
                >
                  <IconButton className="iconShareButton">
                    {" "}
                    <FaTwitter style={{ fontSize: "23px" }} />
                  </IconButton>
                </TwitterShareButton>{" "}
              </Box>
              <Typography variant="body2" color="primary">
                {" "}
                Twitter
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
}
